.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures the video stays in the background */
}

.video-background .overlay {
  position: absolute;
  z-index: 1; /* Ensures the overlay text appears above the video */
  top: -5rem; 
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.video-background .overlay h1 {
  background-color: #960202; /* Semi-transparent red background */
  color: #ffffff; /* White for the text */
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin: 0.5rem 0;
  font-size: 2.2rem;
}

.video-background .overlay p {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 4px;
  margin: 0.5rem 0;
  font-size: 1.4rem;
}
