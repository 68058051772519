.testimonial-slider {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  position: relative;
}

.testimonial-slider h2 {
  color: #960202;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.testimonial-slide {
  color: #333333;
  max-width: 600px; /* Set a max width to center align the content */
  margin: 0 auto; /* Center the slide */
  padding: 0; /* Remove horizontal padding */
}

.testimonial-text {
  font-size: 1.2rem;
  font-style: italic;
  color: #333;
  margin-bottom: 1.5rem;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.author-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #960202;
  margin-bottom: 0.5rem;
}

.author-position {
  color: #666666;
  font-size: 0.9rem;
}

/* Custom Arrow Styles */
.arrow {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: #960202;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
  user-select: none;
}

.next-arrow {
  right: 20px;
}

.prev-arrow {
  left: 20px;
}

.arrow:hover {
  color: #333;
}
