.our-values-container {
  padding: 2rem;
  text-align: center;
}

.our-values-container h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #960202; /* Primary color */
}

.values-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.value-card {
  width: 300px;
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  position: relative;
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.value-card h3 {
  color: #960202;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.value-card h3::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #960202;
  position: absolute;
  bottom: -3px;
  left: 0;
  transition: width 0.3s ease;
}

.value-card:hover h3::after {
  width: 100%;
}

.value-card p {
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}
