body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header, footer {
  background-color: #960202;
  color: white;
  padding: 1rem;
}

nav a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
}

.button {
  background-color: #960202;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.testimonial {
  margin: 1rem 0;
}


.home-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.intro-section {
  background-color: #960202;
  color: white;
  padding: 2rem;
}

.intro-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.intro-section p {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.features-section {
  padding: 2rem;
  background-color: #f9f9f9;
}

.features-section h2 {
  margin-bottom: 1.5rem;
}

/* .features-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.feature-item {
  max-width: 400px;
  background: white;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
} */

.features-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.feature-item {
  flex: 1 1 300px; /* Ensures all items take equal space and stay responsive */
  max-width: 400px; /* Sets a max width to prevent items from getting too wide */
  min-width: 250px; /* Sets a minimum width for responsiveness */
  background: white;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.feature-item h3 {
  color: #960202;
  margin-bottom: 0.5rem;
}

.testimonial-section {
  padding: 2rem;
  background-color: #ffffff;
}

.testimonial-section h2 {
  margin-bottom: 1.5rem;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.call-to-action-section {
  padding: 1rem 1rem; /* Adjusts padding for overall spacing */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* Replace with your theme's gray color */
}

.call-to-action-section h2 {
  margin-top: 0; /* Removes any default top margin */
  margin-bottom: 1.25rem; /* Adds more space below the text */
  color: #fff; /* Adjust text color for contrast on gray */
}


.button {
  background-color: #960202;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
}