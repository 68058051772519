.use-case-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.use-case-card:hover {
  transform: translateY(-5px);
}

.use-case-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
}

.use-case-content {
  padding: 1rem;
}

.use-case-category {
  font-size: 0.9rem;
  color: #666;
  text-align: left; /* Left-align the category */
  margin-bottom: 0.5rem;
}

.use-case-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #960202; /* Primary color */
  text-align: left; /* Left-align title */
  margin: 0; /* Ensure consistent alignment with description */
  transition: color 0.3s ease;
}

.use-case-title .hover-arrow {
  opacity: 0;
  margin-left: 0.3rem;
  transition: opacity 0.3s ease;
}

.use-case-card:hover .use-case-title {
  color: #1a73e8; /* Hover color */
  text-decoration: underline;
}

.use-case-card:hover .hover-arrow {
  opacity: 1;
}

.use-case-description {
  font-size: 0.9rem;
  color: #333;
  text-align: left; /* Left-align description */
  margin-top: 0.5rem;
}
