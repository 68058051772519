.clients-container {
  padding: 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.clients-container h1 {
  color: #960202;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.use-cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Two-column minimum layout */
  gap: 2rem;
  margin-bottom: 2rem;
}

.button {
  margin-top: 1rem;
  background-color: #960202;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.testimonials {
  margin-top: 2rem;
}
