.team-member-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
  max-width: 500px; /* Set a maximum width for each card */
  min-width: 500px;
  margin: 0 auto;   /* Center the card within each grid column */
}

.team-member-card:hover {
  transform: translateY(-5px);
}

.team-member-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.team-member-name {
  font-size: 1.6rem;
  font-weight: bold;
  color: #960202; /* Primary color */
}

.team-member-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.5rem;
}

.team-member-bio {
  font-size: 1.1rem;
  color: #666666;
  margin-bottom: 1rem;
  text-align: left; /* Justify text to the left */
}

.linkedin-icon {
  width: 20px; /* Adjust the size as needed */
  height: 20px;
  vertical-align: middle; /* Aligns the icon nicely with text */
  margin-left: 0.5rem; /* Adds spacing if needed */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

.linkedin-icon:hover {
  transform: scale(1.4); /* Slightly enlarges the icon on hover */
}

.email-link {
  font-size: 20px; /* Adjust the size to make it more visible */
  transition: transform 0.2s ease-in-out; /* Smooth hover effect */
  cursor: pointer;
}

.email-link:hover {
  transform: scale(1.4); /* Slightly enlarges the icon on hover */
}

.team-member-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.email-link, .linkedin-link {
  font-size: 1.5rem;
  color: #960202; /* Primary color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.email-link:hover, .linkedin-link:hover {
  color: #333;
}
