/* Header container */
.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem;
  background-color: white;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Logo styling */
.logo-container {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.logo {
  width: 150px;     /* Set a fixed width for the logo */
  height: auto;      /* Auto-adjust the height to maintain aspect ratio */
  max-width: 180px;  /* Set a maximum width */
  max-height: 100px;  /* Set a maximum height to fit well in the header */
  object-fit: contain;
}

/* Navigation links container */
.nav-links {
  display: flex;
  gap: 2rem;
  margin-right: auto
}

/* Individual link styling */
.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 1.4rem;
  position: relative;
  padding-bottom: 4px;
  transition: color 0.3s ease;
}

/* Hover effect with underline */
.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #960202; /* Your theme color */
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-link:hover {
  color: #960202;
}

.button {
  background-color: #960202;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  align-self: center; /* Align button vertically with other nav items */
  font-size: 1.4rem; /* Match font size with nav links for visual alignment */
}

