.about-intro {
  text-align: center;
  background-color: #f5f5f5; /* Light background for contrast */
  border-bottom: 1px solid #ddd; /* Subtle border below */
}

.about-intro p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6; /* Improve readability */
}

.about-intro h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  color: #960202; /* Matches your red theme */
}

.about-container {
  padding: 2rem;
  text-align: center;
  background-color: #f5f5f5;
  max-width: 1200px; /* Limit the max-width to keep the grid compact */
  margin: 0 auto;
}

.about-container h1 {
  padding: 0.5rem;
  text-align: center;
  max-width: 1200px; /* Limit the max-width to keep the grid compact */
  margin: 0 auto;
  background-color: #f5f5f5;
}


.team-grid {
  display: grid;
  grid-template-columns: 1fr; /* Default to one column */
  gap: 2rem;
  margin-top: 2rem;
}

/* Two-column layout for larger screens */
@media (min-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for wider screens */
  }
}

h1 {
  color: #960202; /* Primary color */
  font-size: 2rem;
  margin-bottom: 1rem;
}
